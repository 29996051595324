import { useCallback, useContext, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { FileBrowserHandle } from "chonky";
import {
  Button,
  Form,
  FormButton,
  FormCheckbox,
  FormGroup,
  FormInput,
  Icon,
} from "semantic-ui-react";

import {
  Access,
  DeadletterInput,
  DeadlettersQuery,
  DeadlettersQueryVariables,
  DeadletterType,
  SendDeadlettersTaskMutation,
  SendDeadlettersTaskMutationVariables,
  WebAdminLogType,
} from "src/codegen-types";
import { AppContext } from "src/Contexts/AppContext";
import { SendDeadlettersTaskGql } from "src/GraphQL/Mutations/DeadletterMutations";
import { GetDeadlettersGql } from "src/GraphQL/Queries/DeadletterQueries";
import { useDocumentTitle } from "src/Hooks/DocumentTitleHook";
import DeadletterFileBrowser from "src/UI/Components/FileBrowser/FileBrowserComponent";
import Page from "src/UI/Components/PageContainer/PageContainerComponent";

import {
  deadletterTypes,
  defaultBatchDelay,
  defaultBatchSize,
  defaultDeleteOnComplete,
  header,
  validationRules,
} from "./DeadletterPage.Constant";

const DeadletterPage: React.FunctionComponent = () => {
  useDocumentTitle("Deadletter");

  const appContext = useContext(AppContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DeadletterInput>({
    defaultValues: {
      batchSize: defaultBatchSize,
      batchDelay: defaultBatchDelay,
      deleteOnComplete: defaultDeleteOnComplete,
    },
  });

  const fileBrowserRef = useRef<FileBrowserHandle>(null);

  const [fileBrowswerError, setFileBrowserError] = useState<string | undefined>(
    undefined,
  );

  const [currentPath, setCurrentPath] = useState("");

  const [deadletterType, setDeadletterType] = useState<DeadletterType>(
    DeadletterType.PICKUP,
  );

  const deadletterQueryResult = useQuery<
    DeadlettersQuery,
    DeadlettersQueryVariables
  >(GetDeadlettersGql, {
    variables: { path: currentPath, deadletterType: deadletterType },
    notifyOnNetworkStatusChange: true,
  });

  const [sendDeadletters, sendDeadlettersRequest] = useMutation<
    SendDeadlettersTaskMutation,
    SendDeadlettersTaskMutationVariables
  >(SendDeadlettersTaskGql, {});

  const handleDeadletterTypeChange = (type: DeadletterType) => {
    setDeadletterType(type);
    setCurrentPath("");
  };

  const onSubmit: SubmitHandler<DeadletterInput> = useCallback(
    async (data: DeadletterInput) => {
      setFileBrowserError(undefined);

      if (!fileBrowserRef.current) {
        setFileBrowserError(
          "File browser reference not found. Something has gone terribly wrong.",
        );
        return;
      }

      const selection: Set<string> = fileBrowserRef.current.getFileSelection();

      if (selection.size === 0) {
        setFileBrowserError("No deadletters selected.");
        return;
      }

      if (appContext?.user?.access !== Access.ADMIN) {
        for (const id in selection) {
          if (id.split("/").length < 5) {
            setFileBrowserError(
              "Only ODL Admins can send deadletters above the month folder.",
            );
            return;
          }
        }
      }

      data.ids = Array.from(selection);
      data.batchSize = Math.trunc(data.batchSize ?? defaultBatchSize);
      data.batchDelay = Math.trunc(data.batchDelay ?? defaultBatchDelay);
      data.deadletterType = deadletterType;

      await sendDeadletters({
        variables: {
          deadletterInput: data,
        },
      });
    },
    [appContext?.user?.access, deadletterType, sendDeadletters],
  );

  return (
    <Page
      headerIcon="envelope"
      headerTitle={header.title}
      headerSubtitle={header.subTitle}
      informationMessage={header.informationMessage}
      showLogForType={WebAdminLogType.DEADLETTER}
      errorMessage={
        deadletterQueryResult.error ||
        sendDeadlettersRequest.error ||
        fileBrowswerError
      }
      loading={deadletterQueryResult.loading || sendDeadlettersRequest.loading}
      successMessage={
        !sendDeadlettersRequest.loading &&
        sendDeadlettersRequest.data?.deadletter.send === true
          ? "Deadletters successfully sent."
          : undefined
      }
    >
      {deadletterTypes.map((key) => (
        <Button
          key={key.text}
          active={deadletterType === key.value}
          onClick={() =>
            handleDeadletterTypeChange(key.value as DeadletterType)
          }
        >
          {key.text}
        </Button>
      ))}
      <DeadletterFileBrowser
        files={deadletterQueryResult?.data?.deadletters}
        ref={fileBrowserRef}
        setCurrentPath={setCurrentPath}
        deadletterType={deadletterType as string}
      />
      <Form inverted={appContext?.isDarkMode} onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormCheckbox
            label="Delete deadletters on complete"
            {...register("deleteOnComplete")}
          />
          <FormInput
            label="Batch Size"
            disabled={appContext?.user?.access !== Access.ADMIN}
            placeholder={defaultBatchSize}
            type="text"
            {...register("batchSize", validationRules.batchSize)}
            error={
              errors.batchSize
                ? { content: validationRules.batchSize?.errorMessage }
                : null
            }
          />
          <FormInput
            label="Batch Delay (ms)"
            disabled={appContext?.user?.access !== Access.ADMIN}
            placeholder={defaultBatchDelay}
            type="text"
            {...register("batchDelay", validationRules.batchDelay)}
            error={
              errors.batchDelay
                ? { content: validationRules.batchDelay?.errorMessage }
                : null
            }
          />
        </FormGroup>
        <FormButton type="submit" primary icon labelPosition="right">
          Send Selection
          <Icon name="envelope" />
        </FormButton>
      </Form>
    </Page>
  );
};

export default DeadletterPage;
