import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { Link } from "react-router-dom";
import {
  Header,
  HeaderContent,
  HeaderSubheader,
  Icon,
  Image,
  Label,
  Menu,
  Segment,
} from "semantic-ui-react";

import { EnvName } from "src/codegen-types";
import { AppContext } from "src/Contexts/AppContext";
import { urls } from "src/globals.constants";
import { useBackendUrl } from "src/Hooks/BackendUrlHook";

import "./MainLayoutComponent.scss";

const MainLayout: React.FunctionComponent<PropsWithChildren> = (
  props: PropsWithChildren,
) => {
  const appContext = useContext(AppContext);
  const backendUrl = useBackendUrl();

  const toggleDarkMode = useCallback(() => {
    appContext?.setIsDarkMode((prev: boolean) => !prev);
  }, [appContext]);

  const mainMenuItems = useMemo((): Array<{
    name: string;
    node: ReactNode;
  }> => {
    return [
      {
        name: "Add Store",
        node: (
          <Menu.Item
            key={urls.addStorePage}
            as={Link}
            to={urls.addStorePage}
            active={appContext?.pageTitle === "Add Store"}
          >
            Add Store
            <Icon name="building outline" />
          </Menu.Item>
        ),
      },
      {
        name: "Send Notification",
        node: (
          <Menu.Item
            key={urls.sendNotificationsPage}
            as={Link}
            to={urls.sendNotificationsPage}
            active={appContext?.pageTitle === "Send Notifications"}
          >
            Send Notifications
            <Icon name="send" />
          </Menu.Item>
        ),
      },
      {
        name: "Refresh Search Index",
        node: (
          <Menu.Item
            key={urls.refreshSearchIndexPage}
            as={Link}
            to={urls.refreshSearchIndexPage}
            active={appContext?.pageTitle === "Refresh Search Index"}
          >
            Refresh Search Index
            <Icon name="refresh" />
          </Menu.Item>
        ),
      },
      {
        name: "Send Deadletters",
        node: (
          <Menu.Item
            key={urls.deadlettersPage}
            as={Link}
            to={urls.deadlettersPage}
            active={appContext?.pageTitle === "Send Deadletters"}
          >
            Send Deadletters
            <Icon name="envelope" />
          </Menu.Item>
        ),
      },
    ].sort((a, b) => a.name.localeCompare(b.name));
  }, [appContext?.pageTitle]);

  return (
    <>
      <Menu
        pointing
        vertical
        inverted={appContext?.isDarkMode}
        className="mainMenu"
      >
        <Header as="h1" inverted={appContext?.isDarkMode} icon>
          <Menu.Item as={Link} header to="/">
            <Image centered size="medium" src="/images/logo.png" />
          </Menu.Item>
          <HeaderContent>
            ODL Web Admin
            <HeaderSubheader>
              <Label
                color={
                  (appContext?.settings.environment === EnvName.DEVELOPMENT &&
                    "green") ||
                  (appContext?.settings.environment === EnvName.TEST &&
                    "blue") ||
                  (appContext?.settings.environment === EnvName.STAGING &&
                    "yellow") ||
                  (appContext?.settings.environment === EnvName.PRODUCTION &&
                    "red") ||
                  "grey"
                }
              >
                {appContext?.settings.environment}
              </Label>
            </HeaderSubheader>
          </HeaderContent>
        </Header>
        {mainMenuItems.map((mainMenuItem) => mainMenuItem.node)}
        <div className="mainMenu__divider" />
        <Menu.Item
          as={Link}
          to={urls.historyPage}
          active={appContext?.pageTitle === "History"}
        >
          History
          <Icon name="history" />
        </Menu.Item>
        <Menu.Item onClick={toggleDarkMode}>
          {appContext?.isDarkMode ? (
            <>
              Switch to light mode
              <Icon name="sun" />
            </>
          ) : (
            <>
              Switch to dark mode
              <Icon name="moon" />
            </>
          )}
        </Menu.Item>

        <Menu.Item as="a" href={backendUrl + "/api/account/logout"}>
          Sign out
          <Icon name="sign-out" />
        </Menu.Item>
      </Menu>

      <Segment
        inverted={appContext?.isDarkMode}
        basic
        padded
        className="mainContainer"
      >
        {props.children}
      </Segment>
    </>
  );
};

export default MainLayout;
