import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ApolloError } from "@apollo/client/errors";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import {
  Button,
  Dimmer,
  DimmerDimmable,
  Grid,
  GridColumn,
  Header,
  HeaderContent,
  Icon,
  Label,
  Loader,
  Segment,
  SemanticICONS,
  Sidebar,
} from "semantic-ui-react";
import HeaderSubHeader from "semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader";

import {
  LogsQuery,
  LogsQueryVariables,
  WebAdminLogType,
} from "src/codegen-types";
import { AppContext } from "src/Contexts/AppContext";
import { urls } from "src/globals.constants";
import { GetLogsGql } from "src/GraphQL/Queries/LogQueries";

import InformationPanel, {
  InformationPanelType,
} from "../InformationPanel/InformationPanelComponent";
import LogTable from "../LogTable/LogTableComponent";

import {
  historyPageSize,
  rootClassName,
  windowWidthThreshold,
} from "./PageContainerComponent.Constants";

import "./PageContainerComponent.scss";

type GraphQLNetError = ApolloError & {
  graphQLErrors?: { result?: { errors?: Array<{ message: string }> } } | null;
  protocolErrors?: { result?: { errors?: Array<{ message: string }> } } | null;
  clientErrors?: { result?: { errors?: Array<{ message: string }> } } | null;
  networkError?: { result?: { errors?: Array<{ message: string }> } } | null;
};

interface PageProps {
  headerIcon: SemanticICONS;
  headerTitle: string;
  headerSubtitle?: string;
  informationMessage: string | ReactNode;
  errorMessage?: string | GraphQLNetError;
  successMessage?: string;
  children?: ReactNode | undefined;
  loading?: boolean;
  showLogForType?: WebAdminLogType;
}

const Page: React.FunctionComponent<PageProps & PropsWithChildren> = (
  props,
) => {
  const appContext = useContext(AppContext);
  const windowWidth = useWindowWidth();

  const [historyIsOpen, setHistoryIsOpen] = useState(false);

  const classNames = useMemo(() => {
    const classes = [rootClassName];

    if (props.showLogForType) {
      classes.push("with-log-panel");
    }

    return classes.join(" ");
  }, [props.showLogForType]);

  const historyTitle = useMemo(() => {
    let historyTitle = "History";

    if (!props.showLogForType) {
      return historyTitle;
    }

    switch (props.showLogForType) {
      case WebAdminLogType.NOTIFICATION: {
        historyTitle += ' for "Send Notifications"';
        break;
      }
      case WebAdminLogType.STORE: {
        historyTitle += ' for "Add Store"';
        break;
      }
      case WebAdminLogType.SEARCH_INDEX_REFRESH: {
        historyTitle += ' for "Refresh Search Index"';
        break;
      }
      case WebAdminLogType.DEADLETTER: {
        historyTitle += ' for "Send Deadletters"';
        break;
      }
    }

    return historyTitle;
  }, [props.showLogForType]);

  const errorMessage = useMemo(() => {
    let errorMessage: string | ReactNode | undefined = "";

    if (props.errorMessage instanceof ApolloError) {
      errorMessage = (
        <>
          <p>{props.errorMessage.message}</p>
          {[
            ...(props.errorMessage.graphQLErrors?.result?.errors ?? []),
            ...(props.errorMessage.protocolErrors?.result?.errors ?? []),
            ...(props.errorMessage.clientErrors?.result?.errors ?? []),
            ...(props.errorMessage.networkError?.result?.errors ?? []),
          ].map((e, i) => (
            <p key={`graphQLError${i}`}>{e.message}</p>
          ))}
        </>
      );
    } else {
      errorMessage = props.errorMessage;
    }

    return errorMessage;
  }, [props.errorMessage]);

  const logsQueryResult = useQuery<LogsQuery, LogsQueryVariables>(GetLogsGql, {
    variables: {
      skip: 0,
      take: historyPageSize,
      logTypeFilter: props.showLogForType,
    },
    notifyOnNetworkStatusChange: true,
    skip:
      !props.showLogForType ||
      (!historyIsOpen && windowWidth < windowWidthThreshold),
  });

  const refetchQuery = useCallback(() => {
    logsQueryResult.refetch();
  }, [logsQueryResult]);

  return (
    <div className={classNames}>
      <Dimmer
        page
        active={historyIsOpen && windowWidth < windowWidthThreshold}
      />
      <Header as="h2" inverted={appContext?.isDarkMode}>
        <Icon name={props.headerIcon} inverted={appContext?.isDarkMode} />
        <HeaderContent>
          {props.headerTitle}
          <HeaderSubHeader>{props.headerSubtitle}</HeaderSubHeader>
        </HeaderContent>
      </Header>
      <InformationPanel
        title="Information"
        message={props.informationMessage}
        type={InformationPanelType.INFO}
        collapsable
      />
      <DimmerDimmable blurring>
        <Dimmer active={props.loading} inverted={!appContext?.isDarkMode} />
        <Loader active={props.loading} inverted={appContext?.isDarkMode} />
        {props.children}
      </DimmerDimmable>
      {props.errorMessage && (
        <InformationPanel
          title="Error"
          message={errorMessage}
          type={InformationPanelType.ERROR}
          collapsable={false}
          duration={20000}
        />
      )}
      {props.successMessage && (
        <InformationPanel
          title="Success"
          message={props.successMessage}
          type={InformationPanelType.SUCCESS}
          collapsable={false}
          duration={10000}
        />
      )}
      {props.showLogForType && (
        <>
          <Label
            attached="top right"
            className={`${rootClassName}__history-toggle`}
          >
            <Button
              icon
              attached="left"
              size="huge"
              onClick={() => setHistoryIsOpen(true)}
              title={historyTitle}
            >
              <Icon name="history"></Icon>
            </Button>
          </Label>
          <Sidebar
            as={Segment}
            className={`${rootClassName}__history-panel`}
            direction="right"
            animation="overlay"
            visible={historyIsOpen || windowWidth >= windowWidthThreshold}
            inverted={appContext?.isDarkMode}
            onHide={() => setHistoryIsOpen(false)}
          >
            <Grid columns={2}>
              <GridColumn verticalAlign="middle">
                <Header inverted={appContext?.isDarkMode}>
                  {historyTitle}
                </Header>
              </GridColumn>
              <GridColumn textAlign="right">
                <Button icon title="Clear search query" onClick={refetchQuery}>
                  <Icon name="refresh" />
                </Button>
                {windowWidth < windowWidthThreshold && (
                  <Button
                    icon="close"
                    title="Close history"
                    onClick={() => setHistoryIsOpen(false)}
                  />
                )}
              </GridColumn>
            </Grid>
            {logsQueryResult.data?.logs.results.length === 0 && (
              <Segment placeholder secondary inverted={appContext?.isDarkMode}>
                <Header textAlign="center">No history here yet.</Header>
              </Segment>
            )}
            <Loader
              active={logsQueryResult.loading}
              inverted={appContext?.isDarkMode}
              inline="centered"
            />
            {!logsQueryResult.loading &&
              logsQueryResult.data &&
              logsQueryResult.data.logs.results.length > 0 && (
                <LogTable logsQueryResult={logsQueryResult} />
              )}

            <Button
              as={Link}
              icon
              labelPosition="right"
              to={urls.historyPage}
              className="formActionGroup"
            >
              View complete ODL Web Admin history here
              <Icon name="arrow right" />
            </Button>
          </Sidebar>
        </>
      )}
    </div>
  );
};

export default Page;
