import gql from "graphql-tag";

import { DeadletterFragmentGql } from "../Fragments/DeadletterFragments";

export const GetDeadlettersGql = gql`
  query Deadletters($path: String!, $deadletterType: DeadletterType!) {
    deadletters(path: $path, deadletterType: $deadletterType) {
      ...Deadletter
    }
  }
  ${DeadletterFragmentGql}
`;
