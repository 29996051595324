import React, {
  forwardRef,
  MutableRefObject,
  useCallback,
  useContext,
} from "react";
import {
  FileActionHandler,
  FileBrowser,
  FileData,
  FileList,
  FileNavbar,
  FileToolbar,
  setChonkyDefaults,
} from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";

import { Deadletter } from "src/codegen-types";
import { AppContext } from "src/Contexts/AppContext";

import "./FileBrowserComponent.scss";

interface FileBrowserProps {
  files: Deadletter[] | undefined;
  deadletterType: string;
  ref: MutableRefObject<any>;
  setCurrentPath: React.Dispatch<React.SetStateAction<string>>;
}

const DeadletterFileBrowser = forwardRef<any, FileBrowserProps>(
  (props, ref) => {
    const appContext = useContext(AppContext);

    // Both of these "as any" are workarounds for a bug in Chonky
    // https://github.com/TimboKZ/Chonky/issues/206
    setChonkyDefaults({ iconComponent: ChonkyIconFA as any });
    const FILE_BROWSER = FileBrowser as any;
    // ----------------------------

    const broswerFiles: FileData[] =
      props.files?.map((file) => {
        return {
          id: file.path,
          name: file.id,
          isDir: file.isFolder,
          openable: file.isFolder,
          size: file.fileSize,
          childrenCount: file.deadletterCount,
        };
      }) ?? [];

    const folderChain = createFolderChain(props.files, props.deadletterType);

    const handleFileAction = useCallback<FileActionHandler>(
      (action) => {
        if (action.id === "open_files") {
          if (action.payload.targetFile?.isDir) {
            const id =
              action.payload.targetFile?.id === "deadletterType"
                ? ""
                : action.payload.targetFile?.id;
            props.setCurrentPath(id ?? "");
          }
        }
      },
      [props],
    );

    return (
      <div className="fileBrowserContainer">
        <FILE_BROWSER
          files={broswerFiles}
          folderChain={folderChain}
          darkMode={appContext?.isDarkMode}
          disableDragAndDrop={true}
          disableDragAndDropProvider={true}
          clearSelectionOnOutsideClick={false}
          onFileAction={handleFileAction}
          ref={ref}
        >
          <FileNavbar />
          <FileToolbar />
          <FileList />
        </FILE_BROWSER>
      </div>
    );
  },
);

function createFolderChain(
  Deadletters: Deadletter[] | undefined,
  deadletterType: string,
): FileData[] {
  if (!Deadletters || Deadletters.length === 0) {
    return [];
  }

  const folderChain: FileData[] = [
    { id: "deadletterType", name: deadletterType, openable: true, isDir: true },
  ];

  const pathParts = Deadletters[0].path
    .split("/")
    .filter((part) => part !== "");
  let currentPath = "";

  pathParts.slice(0, -1).forEach((part) => {
    currentPath = currentPath ? `${currentPath}/${part}` : part;
    folderChain.push({ id: currentPath + "/", name: part, isDir: true });
  });

  return folderChain;
}

export default DeadletterFileBrowser;
