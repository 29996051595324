import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Icon,
  Message,
  Progress,
  SemanticCOLORS,
  SemanticICONS,
} from "semantic-ui-react";

import { AppContext } from "src/Contexts/AppContext";
import { useToggle } from "src/Hooks/ToggleHook";

import "./InformationPanelComponent.scss";

export enum InformationPanelType {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

interface InformationPanelProps {
  title: string;
  message: string | ReactNode;
  type: InformationPanelType;
  collapsable?: boolean;
  duration?: number | null;
}

const InformationPanel: React.FunctionComponent<InformationPanelProps> = (
  props,
) => {
  const [accordionIsActive, toggleAccordionIsActive] = useToggle(false);
  const appContext = useContext(AppContext);

  const { panelColor, panelIcon } = useMemo((): {
    panelColor: SemanticCOLORS;
    panelIcon: SemanticICONS;
  } => {
    switch (props.type) {
      case InformationPanelType.INFO:
        return { panelColor: "blue", panelIcon: "info circle" };
      case InformationPanelType.SUCCESS:
        return { panelColor: "green", panelIcon: "check circle" };
      case InformationPanelType.WARNING:
        return { panelColor: "yellow", panelIcon: "info circle" };
      case InformationPanelType.ERROR:
        return { panelColor: "red", panelIcon: "warning sign" };
      default:
        return { panelColor: "blue", panelIcon: "info circle" };
    }
  }, [props.type]);

  const [visible, setVisible] = useState(true);
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    if (!props.duration) return;

    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev <= 0) {
          clearInterval(interval);
          setVisible(false);
          return 0;
        }

        return prev - 100 / ((props.duration ?? 1) / 50);
      });
    }, 50);

    return () => clearInterval(interval);
  }, [props.duration]);

  if (!visible) return null;

  return (
    <Message color={panelColor} className="informationPanel" floating>
      {props.duration ? (
        <Progress
          percent={progress}
          color={panelColor}
          attached="top"
          inverted={appContext?.isDarkMode}
        />
      ) : null}
      {props.collapsable ? (
        <Accordion>
          <AccordionTitle
            active={accordionIsActive}
            onClick={toggleAccordionIsActive}
          >
            <Icon name="dropdown" />
            <Icon
              name={panelIcon as SemanticICONS}
              color={panelColor as SemanticCOLORS}
            />
            <strong>{props.title}</strong>
          </AccordionTitle>
          <AccordionContent active={accordionIsActive}>
            {props.message}
          </AccordionContent>
        </Accordion>
      ) : (
        <>
          <Icon
            name={panelIcon as SemanticICONS}
            color={panelColor as SemanticCOLORS}
          />
          <strong>{props.title}</strong>
          {typeof props.message === "string" ? (
            <p>{props.message}</p>
          ) : (
            props.message
          )}
        </>
      )}
    </Message>
  );
};

export default InformationPanel;
