import { DeadletterInput, DeadletterType } from "src/codegen-types";
import { FormInputValidation } from "src/globals";
import { getAllKeysOfEnum } from "src/Helpers/EnumHelper";

export const header = {
  title: "Send Deadletters",
  subTitle:
    "Send Deadletters to ODL that have been retried too many times by the event grid.",
  informationMessage: (
    <>
      <p>
        Deadletters are messages that have been retried too many times by the
        event grid. These messages are stored in a blob storage container and
        can be resent to ODL for processing.
      </p>
      <p>
        The deadletters are split into three categories based on their origin
        and saved location in storage; Notification, Pickup and Subscription
        Layer. <br />
        Select a category (Pickup by deafult) and use the explorer to select the
        folder or files to send. <br />
        The numbers displayed on the folders are the number of deadletters in
        that folder, it displays 999 even if there are more than 999
        deadletters. <br />
        Use the checkbox to determine if the deadletters should be deleted from
        storage after processing. <br />
        ODL Admins can change batch size and delay. For other users, these are
        locked to use default values. <br />
        The selected deadletters will be resent to ODL for processing.
      </p>
    </>
  ),
};

export const deadletterTypes = getAllKeysOfEnum(DeadletterType).map((key) => ({
  text: key,
  value: key,
}));

export const defaultBatchSize = 25;
export const defaultBatchDelay = 1000;
export const defaultDeleteOnComplete = false;

export const validationRules = {
  batchSize: {
    required: false,
    pattern: /^(?:[1-9][0-9]{0,2}|1000)$/g,
    errorMessage: "Batch size must be between 1 and 1000",
  },
  batchDelay: {
    required: false,
    pattern: /^([0-9]*)$/g,
    errorMessage: "Batch delay must be a positive number",
  },
} as { [Property in keyof DeadletterInput]: FormInputValidation };
