import gql from "graphql-tag";

export const DeadletterFragmentGql = gql`
  fragment Deadletter on Deadletter {
    id
    path
    isFolder
    isLeafFolder
    deadletterCount
    fileSize
  }
`;
